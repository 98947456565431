/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, { useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const LiftStyle = ({step, order, values, handleChange}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition classNames="slide" timeout={3000}>
          <div>
            <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center"}}>What's your preferred lifting style?</Styled.p>
              <Grid columns={[3]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>
 
                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="functional"
                  name="style"
                  type="radio"
                  value="functional"
                  checked={values.style === "functional" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="functional" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>Funcitonal</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="hiit"
                  name="style"
                  type="radio"
                  value="hiit"
                  checked={values.style === "hiit" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="hiit" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>HIIT</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="power_lifting"
                  name="style"
                  type="radio"
                  value="power_lifting"
                  checked={values.style === "power_lifting" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="power_lifting" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>Power Lifting</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="body_building"
                  name="style"
                  type="radio"
                  value="body_building"
                  checked={values.style === "body_building" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="body_building" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>Body Building</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="dont_know"
                  name="style"
                  type="radio"
                  value="dont_know"
                  checked={values.style === "dont_know" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="dont_know" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>I Don't Know</span>
                  </Flex>
                </label>
            </Grid>
          </div>

        </CSSTransition>
      )}
    </TransitionGroup>
  )

}
