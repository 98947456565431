/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, { useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";


export const Goal = ({step, order, values, handleChange}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])

    switch(values.gymtype) {
      case "home_body":
        return (
          <TransitionGroup component={null}>
            {show && (
              <CSSTransition classNames="slide" timeout={3000}>

                <div>
                  <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>What's your main fitness goal?</Styled.p>
                  <Grid columns={[2]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>    
                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="lose_weight"
                      name="goal"
                      type="radio"
                      value="lose_weight"
                      checked={values.goal=== "lose_weight" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="lose_weight" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Lose Weight</span>
                      </Flex>
                    </label>


                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="maintain_mass"
                      name="goal"
                      type="radio"
                      value="maintain_mass"
                      checked={values.goal=== "maintain_mass" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="maintain_mass" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Maintain Mass</span>
                      </Flex>
                    </label>

                  </Grid>
                </div>  
              </CSSTransition>
            )}
          </TransitionGroup>
        )
      case "home_equip":
        return (
          <TransitionGroup component={null}>
            {show && (
              <CSSTransition classNames="slide" timeout={3000}>
                 <div>
                  <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>What's your main fitness goal?</Styled.p>
                  <Grid columns={[2]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>    
                    
                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="lose_weight"
                      name="goal"
                      type="radio"
                      value="lose_weight"
                      checked={values.goal=== "lose_weight" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="lose_weight" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Lose Weight</span>
                      </Flex>
                    </label>


                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="maintain_mass"
                      name="goal"
                      type="radio"
                      value="maintain_mass"
                      checked={values.goal=== "maintain_mass" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="maintain_mass" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Maintain Mass</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="build_muscle"
                      name="goal"
                      type="radio"
                      value="build_muscle"
                      checked={values.goal=== "build_muscle" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="build_muscle" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Build Muscle</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="get_lean"
                      name="goal"
                      type="radio"
                      value="get_lean"
                      checked={values.goal=== "get_lean" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="get_lean" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Get Lean</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="athleticism"
                      name="goal"
                      type="radio"
                      value="athleticism"
                      checked={values.goal=== "athleticism" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="athleticism" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Athleticism</span>
                      </Flex>
                    </label>

                  </Grid>
                </div>  

          </CSSTransition>
            )}
          </TransitionGroup>
        )
      case "gym":
        return (
          <TransitionGroup component={null}>
            {show && (
              <CSSTransition classNames="slide" timeout={3000}>
                <div>
                  <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>What's your main fitness goal?</Styled.p>
                  <Grid columns={[2]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>    
                    
                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="lose_weight"
                      name="goal"
                      type="radio"
                      value="lose_weight"
                      checked={values.goal=== "lose_weight" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="lose_weight" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Lose Weight</span>
                      </Flex>
                    </label>


                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="maintain_mass"
                      name="goal"
                      type="radio"
                      value="maintain_mass"
                      checked={values.goal=== "maintain_mass" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="maintain_mass" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Maintain Mass</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="build_muscle"
                      name="goal"
                      type="radio"
                      value="build_muscle"
                      checked={values.goal=== "build_muscle" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="build_muscle" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Build Muscle</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="get_lean"
                      name="goal"
                      type="radio"
                      value="get_lean"
                      checked={values.goal=== "get_lean" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="get_lean" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Get Lean</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="athleticism"
                      name="goal"
                      type="radio"
                      value="athleticism"
                      checked={values.goal=== "athleticism" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="athleticism" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Athleticism</span>
                      </Flex>
                    </label>

                    <input
                      sx={{display: "none"}}
                      className="checkbox"
                      id="bikini_body"
                      name="goal"
                      type="radio"
                      value="bikini_body"
                      checked={values.goal=== "bikini_body" ? true : false}
                      onChange={handleChange}
                    />
                    <label htmlFor="bikini_body" sx={{width: ["100%"], textAlign: "center"}}>
                      <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                        <span>Bikini Body</span>
                      </Flex>
                    </label>

                  </Grid>
                </div>  
          </CSSTransition>
            )}
          </TransitionGroup>
        )

      default:
       return(
         <div></div>
       )
    }
}
