/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, { useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const Experience = ({step, order, values, handleChange}) => {


  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition classNames="slide" timeout={3000}>
          <div>
            <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center"}}>HOW MUCH TRAINING EXPERIENCE DO YOU HAVE?</Styled.p>
              <Grid columns={[3]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>
 
                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="beginner"
                  name="experience"
                  type="radio"
                  value="beginner"
                  checked={values.experience === "beginner" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="beginner" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>0-1 Years</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="intermediate"
                  name="experience"
                  type="radio"
                  value="intermediate"
                  checked={values.experience === "intermediate" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="intermediate" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>2-4 Years</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="advanced"
                  name="experience"
                  type="radio"
                  value="advanced"
                  checked={values.experience === "advanced" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="advanced" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>5 or more</span>
                  </Flex>
                </label>
            </Grid>

          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
