/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, { useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";


export const Schedule = ({step, order, values, handleChange}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition classNames="slide" timeout={3000}>
          <div>
            <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>Workout days per week?</Styled.p>
            <Grid columns={[2]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="three_days"
                name="days"
                type="radio"
                value="three_days"
                checked={values.days=== "three_days" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="three_days" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>3-4 Days</span>
                </Flex>
              </label>


              <input
                sx={{display: "none"}}
                className="checkbox"
                id="five_days"
                name="days"
                type="radio"
                value="five_days"
                checked={values.days === "five_days" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="five_days" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>5-6 Days</span>
                </Flex>
              </label>

            </Grid>
          </div>  
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}