/** @jsx jsx */
import { jsx, Container, Styled, Flex, Box, Grid, Button } from 'theme-ui'
import React, {useState} from "react"
import SEO from "../../components/seo"
import logo from '../../images/fc-stacked-black.png'
import {Gender, Goal, GymType, LiftStyle, Experience, Result, Schedule, UserInfo, UserName} from "./"


const QuizContainer = () => {

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    height: "",
    weight: "",
    age: "",
    goal: "",
    gender: "",
    gymtype: "",
    days: "",
    style: "",
    experience: "",

  })
  console.log(values)

  const stepCount = 9
  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    const{name, value} = event.target
    setValues({...values, [name]: value})
  }


  const next = () => {
    let currentStep = step
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 9 ? 10 : currentStep + 1
    setStep(currentStep)
  }
    
  const prev = () => {
    let currentStep = step
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    setStep(currentStep)
  }


  // The "next" and "previous" button functions
const previousButton =() =>{
  let currentStep = step;
  // If the current step is not 1, then render the "previous" button
  if(currentStep !== 1){
    return (
      <Button 
          variant="light"
          type="button" onClick={prev}>
        Previous
      </Button>
    )
  }
  // ...else return nothing
  return null
}

const nextButton = () => {
  let currentStep = step;
  // If the current step is not 3, then render the "next" button
  if(currentStep < stepCount && currentStep > 0){
    return (
      <Flex sx={{width: "100%", justifyContent: "flex-end"}}>
         <Button variant="primary" 
          type="button" onClick={next}>
          Next
        </Button>        
      </Flex>
    )
  } else if(currentStep === stepCount) {
    return (
      <Flex sx={{width: "100%", justifyContent: "flex-end"}}>
        <Button variant="primary" 
          type="button" >
          Submit
        </Button>  
      </Flex>
    )
  }
  // ...else render nothing
  return null;
}



  return (
    <>
      <SEO title="Find your fit" />

      <Flex sx={{width: "100%", position: 'fixed', top: '0', left: '0', right: '0', width: '100%', my: 0, mx: 5, py: 0}}>
        <div>
          <img src={logo} width="100px" sx={{mb: 0}} />
        </div>
      </Flex>
      <Flex sx={{width: "100%", position: 'fixed', top: '106px', left: '0', right: '0', width: '100%', my: 0, py: 0}}>
          <progress id="file" max="100" sx={{width: "100%"}} value={step/stepCount * 100}> {step/stepCount * 100} </progress>
      </Flex>

      <Flex style={{paddingTop: "100px", minHeight: 'calc(100vh - 165px)'}}>
      <Container sx={{mx: "auto"}}>
        <Flex sx={{alignItems: "center", justifyContent: "center", height: "100%"}}>
          <div sx={{width: ["90%","90%","50%"], mx: "auto"}}>
              <GymType handleChange={handleChange} values={values} order={1} step={step} />
              <Experience handleChange={handleChange} values={values} order={2} step={step} />
              <UserName handleChange={handleChange} values={values} order={3} step={step} />
              <UserInfo handleChange={handleChange} values={values} order={4} step={step} />
              <Goal handleChange={handleChange} values={values} order={5} step={step} />
              <Gender handleChange={handleChange} values={values} order={6} step={step} />
              <LiftStyle handleChange={handleChange} values={values} order={7} step={step} />
              <Schedule handleChange={handleChange} values={values} order={8} step={step} />
              <Result handleChange={handleChange} values={values} order={9} step={step} />
              <div sx={{position: "fixed", p: 5, width: "100%", bottom: 0, right: 0, left: 0, backgroundColor: 'white'}}>
                <Flex sx={{width: "100%", alignItems: "center", justifyContent: "space-between"}}>
                  {previousButton()}
                  {nextButton()}       
                </Flex>
              </div>
            </div>
          </Flex>
        </Container>
      </Flex>
    </>
  )
}

export default QuizContainer;