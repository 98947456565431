import React from "react"
import Quiz from "../components/ProgramQuiz/QuizContainer";
import "../styles/global.css"

const GetStarted = () => {

  return(
    <Quiz />
  )
}

export default GetStarted;