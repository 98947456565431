/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, { useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";



export const GymType = ({step, order, handleChange, values}) => {

    const [show, setShow] = useState(false)

    useEffect(()=> {
      if (step === order){
        setShow(!show)
      } else {
        setShow(false)
      }
    },[step])


  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition classNames="slide" timeout={3000}>
          <div>
              <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>Which applies most to you</Styled.p>
              <Grid columns={[3]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>
 
                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="home_body"
                  name="gymtype"
                  type="radio"
                  value="home_body"
                  checked={values.gymtype === "home_body" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="home_body" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>Home, Bodyweight</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="home_equip"
                  name="gymtype"
                  type="radio"
                  value="home_equip"
                  checked={values.gymtype === "home_equip" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="home_equip" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>Home, Equipment</span>
                  </Flex>
                </label>

                <input
                  sx={{display: "none"}}
                  className="checkbox"
                  id="gym"
                  name="gymtype"
                  type="radio"
                  value="gym"
                  checked={values.gymtype === "gym" ? true : false}
                  onChange={handleChange}
                />
                <label htmlFor="gym" sx={{width: ["100%"], textAlign: "center"}}>
                  <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                    <span>Gym</span>
                  </Flex>
                </label>
            </Grid>
          </div>
  
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
