/** @jsx jsx */
import { jsx, Container, Styled, Grid, Flex, Box} from 'theme-ui'
import {useEffect, useState} from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { navigate } from "gatsby"

const sentence = "we are matching you with your perfect program"

export const Result = ({step, order, values}) => {

  const [show, setShow] = useState(false)

  const destination = typeof window !== `undefined` ? window : null

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


  const programRedirect = (url) => {
    setTimeout(() => {
      destination.location.href=url
    }, 5000)
  }



if (show) {
    if (values.gymtype === "home_body") {
      return(
        <TransitionGroup component={null}>
          {show && (
            <CSSTransition classNames="slide" timeout={3000}>
              <div>
                <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                  <div sx={{height: "100px"}}>
                    <div className="loader">Loading...</div>
                  </div>
                  {programRedirect("https://www.fitnessculture.com/programs/men/at-home-bodyweight")}
                </Flex>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      )
    } else if (values.gymtype === "home_equip"){
      return(
        <TransitionGroup component={null}>
          {show && (
          <CSSTransition classNames="slide" timeout={3000}>
            <div>
              <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                <div sx={{height: "100px"}}>
                  <div className="loader">Loading...</div>
                </div>
                {programRedirect("https://www.fitnessculture.com/programs/men/at-home-dumbbell")}
              </Flex>
            </div>
          </CSSTransition>
          )}
        </TransitionGroup>
      )
    } else if (values.gymtype === "gym") {
      switch(values.gender) {
        case "male":
          switch(values.goal){
            case "build_muscle":
              switch(values.style) {
                case "body_building":
                  return(
                    <TransitionGroup component={null}>
                      {show && (
                      <CSSTransition classNames="slide" timeout={3000}>
                        <div>
                          <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                            <div sx={{height: "100px"}}>
                              <div className="loader">Loading...</div>
                            </div>
                            {programRedirect("https://www.fitnessculture.com/programs/men/swole")}
                          </Flex>
                        </div>
                      </CSSTransition>
                      )}
                    </TransitionGroup>
                  )
                case "dont_know":
                  return(
                    <TransitionGroup component={null}>
                      {show && (
                      <CSSTransition classNames="slide" timeout={3000}>
                        <div>
                      <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                        <div sx={{height: "100px"}}>
                          <div className="loader">Loading...</div>
                        </div>
                        {programRedirect("https://www.fitnessculture.com/programs/men/swole")}
                      </Flex>
                      </div>
                      </CSSTransition>
                      )}
                    </TransitionGroup>
                  )
                case "power_lifting":
                  return(
                    <TransitionGroup component={null}>
                    {show && (
                    <CSSTransition classNames="slide" timeout={3000}>
                      <div>
                      <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                        <div sx={{height: "100px"}}>
                          <div className="loader">Loading...</div>
                        </div>
                        {programRedirect("https://www.fitnessculture.com/programs/men/power")}
                      </Flex>
                      </div>
                      </CSSTransition>
                      )}
                    </TransitionGroup>
                  )
                }
            case "get_lean":
              switch(values.style) {
                case "hiit":
                  return(
                    <TransitionGroup component={null}>
                    {show && (
                    <CSSTransition classNames="slide" timeout={3000}>
                      <div>
                    <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                      <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                      <div sx={{height: "100px"}}>
                        <div className="loader">Loading...</div>
                      </div>
                      {programRedirect("https://www.fitnessculture.com/programs/men/lean")}
                    </Flex>
                    </div>
                      </CSSTransition>
                      )}
                    </TransitionGroup>
                  )
                case "dont_know":
                  return(
                    <TransitionGroup component={null}>
                    {show && (
                    <CSSTransition classNames="slide" timeout={3000}>
                      <div>
                      <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                        <div sx={{height: "100px"}}>
                          <div className="loader">Loading...</div>
                        </div>
                        {programRedirect("https://www.fitnessculture.com/programs/men/lean")}
                      </Flex>
                      </div>
                      </CSSTransition>
                      )}
                    </TransitionGroup>
                  )
                case "functional":
                  return(
                    <TransitionGroup component={null}>
                    {show && (
                    <CSSTransition classNames="slide" timeout={3000}>
                      <div>
                    <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                      <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                      <div sx={{height: "100px"}}>
                        <div className="loader">Loading...</div>
                      </div>
                      {programRedirect("https://www.fitnessculture.com/programs/men/functional")}
                    </Flex>
                    </div>
                      </CSSTransition>
                      )}
                    </TransitionGroup>
                  )
                }
            case "athleticism":
              return(
              <TransitionGroup component={null}>
                {show && (
                <CSSTransition classNames="slide" timeout={3000}>
                  <div>
                  <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                    <div sx={{height: "100px"}}>
                      <div className="loader">Loading...</div>
                    </div>
                    {programRedirect("https://www.fitnessculture.com/programs/men/athlete")}
                  </Flex>
                  </div>
                </CSSTransition>
                )}
              </TransitionGroup>
              )
            default:
              return(
              <TransitionGroup component={null}>
                {show && (
                <CSSTransition classNames="slide" timeout={3000}>
                  <div>
                  <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                    <div sx={{height: "100px"}}>
                      <div className="loader">Loading...</div>
                    </div>
                    {programRedirect("https://www.fitnessculture.com/programs/men/lean")}
                  </Flex>
                  </div>
                </CSSTransition>
                )}
              </TransitionGroup>
              )
          }
        case "female":
          switch(values.goal){
            case "build_muscle":        
              return(
                <TransitionGroup component={null}>
                {show && (
                <CSSTransition classNames="slide" timeout={3000}>
                  <div>
                  <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                    <div sx={{height: "100px"}}>
                      <div className="loader">Loading...</div>
                    </div>
                    {programRedirect("https://www.fitnessculture.com/programs/women/w-power")}
                  </Flex>
                  </div>
                </CSSTransition>
                )}
              </TransitionGroup>
              )
            case "bikini_body":
              switch(values.days) {
                case "5_days":
                  return(
                    <TransitionGroup component={null}>
                    {show && (
                    <CSSTransition classNames="slide" timeout={3000}>
                      <div>
                      <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                        <div sx={{height: "100px"}}>
                          <div className="loader">Loading...</div>
                        </div>
                        {programRedirect("https://www.fitnessculture.com/programs/women/bikini")}
                      </Flex>
                      </div>
                </CSSTransition>
                )}
              </TransitionGroup>
                  )
                case "3_days":
                  return(
                    <TransitionGroup component={null}>
                    {show && (
                    <CSSTransition classNames="slide" timeout={3000}>
                      <div>
                      <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                        <div sx={{height: "100px"}}>
                          <div className="loader">Loading...</div>
                        </div>
                        {programRedirect("https://www.fitnessculture.com/programs/women/tone")}
                      </Flex>
                      </div>
                  </CSSTransition>
                  )}
                </TransitionGroup>
                  )
                }
            case "athleticism":
              return(
                <TransitionGroup component={null}>
                {show && (
                <CSSTransition classNames="slide" timeout={3000}>
                  <div>
                  <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                    <div sx={{height: "100px"}}>
                      <div className="loader">Loading...</div>
                    </div>
                    {programRedirect("https://www.fitnessculture.com/programs/women/w-athlete")}
                  </Flex>
                  </div>
                </CSSTransition>
                )}
              </TransitionGroup>
              )
            default:
              return(
                <TransitionGroup component={null}>
                {show && (
                <CSSTransition classNames="slide" timeout={3000}>
                  <div>
                  <Flex sx={{flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <h2 className="mb-6 text-xl">{values.first_name}, {sentence}</h2>
                    <div sx={{height: "100px"}}>
                      <div className="loader">Loading...</div>
                    </div>
                    {programRedirect("https://www.fitnessculture.com/programs/women/tone")}
                  </Flex>
                  </div>
                </CSSTransition>
                )}
              </TransitionGroup>
              )
          }
      }
    } else {
      return (
        null
      )
    } 


  } else {
    return null;
  }
} 

