/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, { useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";



export const Gender = ({step, order, values, handleChange}) => {


  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition classNames="slide" timeout={3000}>

          <div>
            <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>What's your sex?</Styled.p>
            <Grid columns={[2]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="male"
                name="gender"
                type="radio"
                value="male"
                checked={values.gender === "male" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="male" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>Male</span>
                </Flex>
              </label>


              <input
                sx={{display: "none"}}
                className="checkbox"
                id="female"
                name="gender"
                type="radio"
                value="female"
                checked={values.gender === "female" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="female" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>Female</span>
                </Flex>
              </label>

            </Grid>
          </div>  
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
