/** @jsx jsx */
import { jsx, Flex, Styled, Grid, Select, Input, Label} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";



export const UserInfo = ({step, order, handleChange, values}) => {


  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])


    return (
      <TransitionGroup component={null}>
        {show && (
          <CSSTransition classNames="slide" timeout={3000}>
            <div>
              <Styled.p sx={{fontWeight: "heading", my: "0", textAlign: "center", textTransfrom: "uppercase"}}>Hey, {values.first_name}. Can you tell us a little bit more about you?</Styled.p>
              <Styled.p>This infor helps us understand what type of program is best for you, physically.</Styled.p>
                <Grid columns={[3]} gap={2} sx={{width: "100%", mt: 5, justifyContent: "center"}}>

                  <div>
                    <Label htmlFor="height">Height</Label>
                    <Select id="height" name="height" value={values.height} onChange={handleChange}>
                      <option value=""></option>
                      {[56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90].map((s, index) => {
                        return <option key={index} value={s}>{parseInt(s/12)}'{parseInt(s%12)}"</option>
                      })}
                    </Select>
                  </div>
              
                  <div>
                    <Label htmlFor="weight">Weight</Label>
                    <Input
                      id="weight"
                      name="weight"
                      type="text"
                      placeholder="Weight"
                      value={values.weight}
                      onChange={handleChange}
                    />
                  </div>
                  
                  <div>
                    <Label htmlFor="age">Age</Label>
                    <Input
                      id="age"
                      name="age"
                      type="text"
                      placeholder="Age"
                      value={values.age}
                      onChange={handleChange}
                    />
                  </div>
            </Grid>
          </div>
      </CSSTransition>
      )}
    </TransitionGroup>
  )
}